<template>
  <v-container
    grid-list-xl
    fluid
    style="padding-top: 0;">
    <v-layout wrap>
      <v-flex md12>
        <material-card
          :title="$t('portal.configLogo')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <!-- Issuance img -->
                <v-flex
                  class="text-center"
                  style="margin: auto;"
                  xs12
                  md6
                >
                  <div class="text-left mb-2"><span>Logo</span></div>
                  <image-picker
                    key="logo"
                    :image-width="240"
                    :image-height="90"
                    :image-url="logo.imageUrl"
                    exceptation-ratio="8:3"
                    max-size-upload="3MB"
                    recommend-dimension="80x30"
                    @onSelectedImageFile="onSelectBannerImg"
                    @onResetImage="onClickRemoveBannerImg"
                  />
                </v-flex>
                <!-- Partner logo -->
                <v-flex
                  class="text-center"
                  style="margin: auto;"
                  xs12
                  md6
                >
                  <div class="text-left mb-2"><span>Logo đối tác</span></div>
                  <image-picker
                    key="partner"
                    :image-width="240"
                    :image-height="90"
                    :image-url="partnerLogo.imageUrl"
                    exceptation-ratio="8:3"
                    max-size-upload="3MB"
                    recommend-dimension="80x30"
                    @onSelectedImageFile="onSelectPartnerImg"
                    @onResetImage="onClickRemovePartnerImg"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSaveConfig(layoutConfigKey.logo)"
              >
                <span style="text-transform: none;">{{
                  $t("common.save")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
        <material-card
          :title="$t('portal.configBackgroundMenu')"
          color="green"
          flat
          full-width
          class="bg-white"
        >
          <v-form>
            <v-container py-0>
              <v-layout wrap>
                <v-flex
                  xs12
                  md6
                  class="d-flex justify-content-end"
                >
                  <Sketch
                    v-model="colors"
                    @input="onChangeColor"/>
                </v-flex>
                <v-flex
                  xs12
                  md6
                >
                  <h5>{{ $t('portal.preview') }}</h5>
                  <div
                    :style="{ background: color.code }"
                    class="color-review"
                  />
                </v-flex>
              </v-layout>
              <v-btn
                style="margin-right: 0;"
                class="pull-right"
                color="success"
                @click="onSaveConfig(layoutConfigKey.bgMenu)"
              >
                <span style="text-transform: none;">{{
                  $t("common.save")
                }}</span>
              </v-btn>
            </v-container>
          </v-form>
        </material-card>
      </v-flex>
    </v-layout>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar" />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { uploadFileToMediaAgent } from 'utils/functionUtils'
import stringUtils from 'utils/stringUtils'
import ToastType from 'enum/toastType'
import { Sketch } from 'vue-color'
import ImagePicker from 'Components/ImagePicker.vue'
export default {
  components: {
    Sketch,
    ImagePicker
  },
  data: () => ({
    color: {
      code: '#ffffff'
    },
    logo: {
      imageUrl: null,
      imageFile: null,
      imageSize: null
    },
    partnerLogo: {
      imageUrl: null,
      imageFile: null,
      imageSize: null
    },
    noImg: require('@/assets/noimage.png'),
    width: null,
    height: null,
    layoutConfigKey: {
      logo: stringUtils.LayoutConfigKey.LOGO,
      bgMenu: stringUtils.LayoutConfigKey.BACKGROUND_MENU,
      partnerLogo: stringUtils.LayoutConfigKey.LOGO_PARTNER
    },
    colors: {
      hex: '#ffffff'
    }
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR'
    ])
  },
  watch: {},
  created () {
    this.GET_PORTAL_LAYOUT_CONFIG().then(
      function (res) {
        let data = res.data
        let logoArr = data.filter((element) => {
          return element.key === this.layoutConfigKey.logo
        })
        let bgMenuArr = data.filter((element) => {
          return element.key === this.layoutConfigKey.bgMenu
        })
        let logoPartnerArr = data.filter((element) => {
          return element.key === this.layoutConfigKey.partnerLogo
        })
        if (logoPartnerArr.length > 0) {
          this.partnerLogo.imageUrl = logoPartnerArr[0].value
        }
        if (logoArr.length > 0) {
          this.logo.imageUrl = logoArr[0].value
        }
        if (bgMenuArr.length > 0) {
          this.color.code = bgMenuArr[0].value
        }
      }.bind(this)
    )
  },
  mounted () {},
  methods: {
    onChangeColor: function (value) {
      this.colors.hex = value.hex
      this.color.code = value.hex
    },
    onSaveConfig: async function (key) {
      if (this.logo.imageSize > 3000000) {
        this.ON_SHOW_TOAST({
          message: this.$t('common.imageCannotExceed3MB'),
          styleType: 'danger'
        })
        return
      }
      let filter = {
        type: 'Entity',
        key: key,
        value: key === this.layoutConfigKey.logo ? this.logo.imageUrl : this.color.code
      }
      if (this.logo.imageFile && key === this.layoutConfigKey.logo) {
        await this.uploadBannerImage()
        filter['value'] = this.logo.imageUrl
      }
      this.CONFIG_LAYOUT_PORTAL(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      )
      if (this.partnerLogo.imageFile && key === this.layoutConfigKey.logo) {
        await this.uploadLogoParterImage()
        filter['value'] = this.partnerLogo.imageUrl
      } else if (key === this.layoutConfigKey.logo) {
        filter['value'] = null
      }
      filter['key'] = this.layoutConfigKey.partnerLogo
      this.CONFIG_LAYOUT_PORTAL(filter).then(
        function () {
          this.ON_SHOW_TOAST({
            message: this.$t('common.success'),
            styleType: ToastType.SUCCESS
          })
        }.bind(this)
      )
    },
    uploadLogoParterImage: async function () {
      this.partnerLogo.imageUrl = await uploadFileToMediaAgent(this.partnerLogo.imageFile, 'eVoucher/Portal/Logo')
    },
    /**
     * Upload advertisement image
     */
    uploadBannerImage: async function () {
      this.logo.imageUrl = await uploadFileToMediaAgent(this.logo.imageFile, 'eVoucher/Portal/Logo')
    },
    /**
     * Select issuance img
     */
    onSelectBannerImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.logo.imageSize = files[0].size
      this.getResultFile(files[0])
      this.logo.imageUrl = URL.createObjectURL(files[0])
    },
    /**
     * Select issuance img
     */
    onSelectPartnerImg: function (e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.partnerLogo.imageSize = files[0].size
      this.getResultPartnerFile(files[0])
      this.partnerLogo.imageUrl = URL.createObjectURL(files[0])
    },
    /**
     * Remove issuance img
     */
    onClickRemoveBannerImg: function () {
      this.logo.imageUrl = null
      this.logo.imageFile = null
    },
    /**
     * Remove issuance img
     */
    onClickRemovePartnerImg: function () {
      this.partnerLogo.imageUrl = null
      this.partnerLogo.imageFile = null
    },
    getResultFile: function (file) {
      let vm = this
      vm.logo.imageFile = file
    },
    getResultPartnerFile: function (file) {
      let vm = this
      vm.partnerLogo.imageFile = file
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'CONFIG_LAYOUT_PORTAL',
      'GET_PORTAL_LAYOUT_CONFIG'
    ])
  }
}
</script>

<style lang="scss" scoped>
/deep/.color-review {
  width: 100%;
  height: 305px;
  max-height: 305px;
  border: 1px solid #ddd;
  border-radius: 4px;
  caret-color: auto;
  &:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
}
</style>
